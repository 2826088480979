import MenuIcon from '@mui/icons-material/Menu';
import { useCallback, useState } from 'react';

import Logo from '../../Logo';
import NavBar from '../NavBar';

import useStyles from './styles';

function MobileHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles({ isMenuOpen });

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(prevState => !prevState);
  }, []);

  return (
    <header className={classes.root}>
      <div className={classes.wrapper}>
        <Logo />
        <MenuIcon className={classes.menuIcon} onClick={handleMenuClick} />
      </div>
      <div />
     {isMenuOpen && <NavBar setIsOpen={setIsMenuOpen} />}
    </header>
  );
}

export default MobileHeader;
