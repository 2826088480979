const about_settings = {
  title: 'Մեր մասին',
  about_text1: `Բարի գալուստ Lilit-87, Ձեր
          առաջատար ասֆալտապատման ընկերություն: Ավելի քան 10+ տարվա փորձ ունենալով,
          մենք հպարտանում ենք, որ մատուցում ենք բարձր որակի, ամուր և մատչելի 
          արտադրանք՝ ինչպես բնակարանային, այնպես էլ կոմերցիոն հաճախորդների համար:
          `,
  about_text2: `Մեր նպատակն է ապահովել արագ որակյալ աշխատանք և ստեղծել էսթետիկորեն հաճելի արդյունք:
                Մենք ունենք պրոֆեսիոնալ անձնակազմ, որը միշտ պատրաստ է օգնելու ձեզ ցանկացած հարցում:`,
};

export default about_settings;
