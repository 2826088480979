import { createUseStyles } from 'react-jss';
import { BreakPoints } from 'constants/styles';

import mediaQuery from 'utils/mediaQuery';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    padding: [0, 40],
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
    [mediaQuery(BreakPoints.Small)]: {
      padding: 0,
    },
  },
  divider: {
    width: '100%',
    height: 2,
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 10,
  },
  image: {
    width: '49%',
    [mediaQuery(BreakPoints.Small)]: {
      width: '100%',
    },
  },
}, {
  name: 'work',
});

export default useStyles;
