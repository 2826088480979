import Divider from '@mui/material/Divider';
import SECTIONS from 'constants/sections';
import work_settings from 'constants/work';

import useStyles from './styles';

function Work() {
  const classes = useStyles();
  const { title, images } = work_settings;
  return (
    <section id={SECTIONS.WORK} className={classes.root}>
      <Divider className={classes.divider} />
      <h2>{title}</h2>
      <div className={classes.imagesContainer}>
        {
          images.map(src => (
            <img key={src} src={src} className={classes.image} />
          ))
        }
      </div>
    </section>
  );
}

export default Work;
