import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
  },
  map: {
    width: '100%',
  },

}, {
  name: 'google-map',
});

export default useStyles;
