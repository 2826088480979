import Button from '@mui/material/Button';
import start_settings from 'constants/start';
import SECTIONS from 'constants/sections';

import preview_image from 'assets/images/start-preview.jpg';

import useStyles from './styles';

function Start() {
  const classes = useStyles();
  const { button, title } = start_settings;
  return (
    <section id={SECTIONS.START} className={classes.root}>
    <img src={preview_image} width="100%" height="100%" />
      <div className={classes.contentWrapper}>
        <p className={classes.title}>{title}</p>
        <Button className={classes.button} variant="contained" href={`#${SECTIONS.ABOUT}`}>{button}</Button>
      </div>
    </section>
  );
}

export default Start;
