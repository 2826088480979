import SECTIONS from './sections';
import { INavigationSettings } from './types';

const navigation_settings: INavigationSettings = {
  SectionTitles: [
    {
      title: 'Գլխավոր',
      url: `#${SECTIONS.START}`,
    },
    {
      title: 'Մեր մասին',
      url: `#${SECTIONS.ABOUT}`,
    },
    {
      title: 'Աշխատանք',
      url: `#${SECTIONS.WORK}`,
    },
    {
      title: 'Կապ',
      url: `#${SECTIONS.CONTACT}`,
    },
  ],
};
export default navigation_settings;
