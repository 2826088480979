import { createUseStyles } from 'react-jss';
import { BreakPoints } from 'constants/styles';

import mediaQuery from 'utils/mediaQuery';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
    padding: [0, 40],
    marginBottom: 40,
    [mediaQuery(BreakPoints.Small)]: {
      padding: 0,
    },
  },
  video: {
    maxWidth: '100%',
  },
  divider: {
    width: '100%',
    height: 2,
  },
}, {
  name: 'video-section',
});

export default useStyles;
