import useStyles from './styles';

function GoogleMap() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
          <iframe
            className={classes.map}
            title="map"
            // eslint-disable-next-line max-len
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2822.9512994525594!2d44.51237115743145!3d40.23812982916651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDE0JzE3LjQiTiA0NMKwMzAnNTMuMyJF!5e0!3m2!1sen!2sam!4v1722089109666!5m2!1sen!2sam"
            width="600"
            height="450"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
    </div>
  );
}

export default GoogleMap;
