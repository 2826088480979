import { createUseStyles } from 'react-jss';
import { Colors } from 'constants/styles';

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: '110%',
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 20,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    background: '#fff',

  },
  link: {
    color: Colors.Texts.base,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 20,
    '&:hover': {
      color: '#f5bf23',
    },
  },
}, {
  name: 'navbar',
});

export default useStyles;
