import VideoSection from 'sections/VideoSection';
import About from 'sections/About';
import Contact from 'sections/Contact';
import Start from 'sections/Start';
import Work from 'sections/Work';

import Header from './components/Header';
import useStyles from './styles';

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <Start />
      <VideoSection />
      <About />
      <Work />
      <Contact />
    </div>
  );
}

export default App;
