import React, { useCallback, useEffect, useRef } from 'react';
import navigation_settings from 'constants/navigation';

import useStyles from './styles';
import INavBar from './types';

function NavBar({ setIsOpen }: INavBar) {
  const classes = useStyles();
  const navRef = useRef<any>(null);

  const { SectionTitles } = navigation_settings;

  const handleItemClick = () => {
    setIsOpen(false);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (navRef.current && !navRef.current?.contains(event.target)) {
      setIsOpen(false);
    }
  }, [setIsOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div ref={navRef} className={classes.root}>
      {
        SectionTitles.map(({ url, title }) => (
          <a href={url} onClick={handleItemClick} className={classes.link}>{title}</a>
        ))
      }
    </div>
  );
}

export default NavBar;
