import { createUseStyles } from 'react-jss';
import { Colors } from 'constants/styles';

const useStyles = createUseStyles({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 999,
  },
  wrapper: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [20, 40],
    background: '#ffff',
    boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .1)',
  },
  link: {
    color: Colors.Texts.base,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 20,
    '&:hover': {
      color: '#f5bf23',
    },
  },
  linksWrapper: {
    display: 'flex',
    gap: 16,
  },
}, {
  name: 'desktop-header',
});

export default useStyles;
