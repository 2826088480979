import { BreakPointsTypes } from './types';

export const Colors = {
  Texts: {
    base: '#000000',
    positive: '#ffff',
  },
};

export const BreakPoints: BreakPointsTypes = Object.freeze({
  ExtraLarge: 1599,
  Large: 1365,
  Medium: 1279,
  Small: 1023,
});
