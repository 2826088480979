import { createUseStyles } from 'react-jss';
import { BreakPoints, Colors } from 'constants/styles';

import mediaQuery from 'utils/mediaQuery';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    padding: [0, 40],
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'center',
  },
  divider: {
    width: '100%',
    height: 2,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  linksWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
  },
  link: {
    color: Colors.Texts.base,
    fontWeight: 500,
    '&:hover': {
      color: '#f5bf23',
    },
    [mediaQuery(BreakPoints.Small)]: {
      fontSize: 10,
    },
  },
  emailTelWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  copyRightWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailTelItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  copyRight: {
    [mediaQuery(BreakPoints.Small)]: {
      fontSize: 12,
    },
  },
}, {
  name: 'contact',
});

export default useStyles;
