import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 999,
  },
  wrapper: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [10, 20],
    background: '#ffff',
    boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .1)',
  },
  menuIcon: {
    width: 50,
    height: 50,
    color: (props: { isMenuOpen: boolean }) => (props.isMenuOpen ? '#f5bf23' : '#111'),
    cursor: 'pointer',
  },

}, {
  name: 'mobile-header',
});
export default useStyles;
