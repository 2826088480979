import navigation_settings from 'constants/navigation';

import Logo from '../../Logo';

import useStyles from './styles';

function Header() {
  const classes = useStyles();
  const { SectionTitles } = navigation_settings;
  return (
    <header className={classes.root}>
      <div className={classes.wrapper}>
        <Logo />
        <div className={classes.linksWrapper}>
          {
            SectionTitles.map(({ title, url }) => (
              <a href={url} className={classes.link}>{title}</a>
            ))
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
