import useIsMobile from 'hooks/useIsMobile';
import logo from 'assets/images/logo.jpg';

function Logo() {
  const isMobile = useIsMobile();
  return (
    <img src={logo} height={80} width={200} />
  );
}

export default Logo;
