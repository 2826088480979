import Divider from '@mui/material/Divider';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SECTIONS from 'constants/sections';
import contact_settings from 'constants/contact';
import navigation_settings from 'constants/navigation';

import GoogleMap from 'components/GoogleMap';

import useStyles from './styles';

function Contact() {
  const classes = useStyles();

  const {
    title, phone_number, email, copyRight,
  } = contact_settings;

  const { SectionTitles } = navigation_settings;

  return (
    <section id={SECTIONS.CONTACT} className={classes.root}>
      <Divider className={classes.divider} />
      <h2>{title}</h2>
      <GoogleMap />
      <Divider className={classes.divider} />
      <div className={classes.wrapper}>
        <div className={classes.linksWrapper}>
          {
            SectionTitles.map(({ title, url }) => (
              <a href={url} className={classes.link}>{title}</a>
            ))
          }
        </div>
      <Divider className={classes.divider} />
        <div className={classes.emailTelWrapper}>
          <div className={classes.emailTelItem}>
          <LocalPhoneIcon />
          <a className={classes.link} href={`tel:${phone_number}`}>{phone_number}</a>
          </div>
          <div className={classes.emailTelItem}>
          <MailOutlineIcon />
          <a className={classes.link} href={`mailto:${email}`}>{email}</a>
          </div>

        </div>
      <Divider className={classes.divider} />
        <div className={classes.copyRightWrapper}>
          <span className={classes.copyRight}>{copyRight}</span>
        </div>
      </div>
    </section>
  );
}

export default Contact;
