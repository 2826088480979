import Divider from '@mui/material/Divider';
import SECTIONS from 'constants/sections';
import about_settings from 'constants/about';

import preview_image from 'assets/images/about-preview.jpeg';

import useStyles from './styles';

function About() {
  const classes = useStyles();
  const { title, about_text1, about_text2 } = about_settings;
  return (
    <section id={SECTIONS.ABOUT} className={classes.root}>
      <Divider className={classes.divider} />
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.wrapper}>
        <div className={classes.imageContainer}>
        <img src={preview_image} width="100%" />
        </div>
        <div className={classes.textContainer}>
          <span className={classes.aboutText}>{about_text1}</span>
          <br />
          <span className={classes.aboutText}>{about_text2}</span>
        </div>
      </div>
    </section>
  );
}

export default About;
