import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}, {
  name: 'app',
});
export default useStyles;
