import { createUseStyles } from 'react-jss';
import { BreakPoints, Colors } from 'constants/styles';

import mediaQuery from 'utils/mediaQuery';

const useStyles = createUseStyles({
  root: {
    height: '80vh',
    width: '100%',
    padding: [0, 40],
    marginBottom: 40,
    [mediaQuery(BreakPoints.Small)]: {
      padding: 0,
    },
  },
  video: {
    maxWidth: '100%',
    width: '100%',
  },
  contentWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
  },
  title: {
    fontSize: 70,
    fontWeight: 600,
    color: Colors.Texts.positive,
    [mediaQuery(BreakPoints.Small)]: {
      fontSize: 34,
    },
  },
  button: {
    color: Colors.Texts.positive,
    background: '#f5bf23',
    '&:hover': {
      background: Colors.Texts.base,
    },
  },
}, {
  name: 'start',
});
export default useStyles;
