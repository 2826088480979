import video_settings from 'constants/video';
import { Divider } from '@mui/material';

import useStyles from './styles';

function VideoSection() {
  const classes = useStyles();

  const { title } = video_settings;

  return (
    <section className={classes.root}>
      <Divider className={classes.divider} />
      <h2>{title}</h2>
      <video
        className={classes.video}
        src="https://cdn.pixabay.com/video/2020/06/20/42698-432087121_large.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
    </section>
  );
}

export default VideoSection;
