import { lazy, Suspense } from 'react';

import useIsMobile from 'hooks/useIsMobile';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

function Header() {
  const isMobile = useIsMobile();
  const Header = isMobile ? MobileHeader : DesktopHeader;

  return <Header />;
}

export default Header;
