import work_imag_1 from 'assets/images/work/work1.jpg';
import work_imag_2 from 'assets/images/work/work2.png';
import work_imag_3 from 'assets/images/work/work3.jpg';
import work_imag_4 from 'assets/images/work/work4.webp';

const work_settings = {
  title: 'Աշխատանք',
  images: [work_imag_1, work_imag_2, work_imag_3, work_imag_4],
};

export default work_settings;
