import { createUseStyles } from 'react-jss';
import { BreakPoints } from 'constants/styles';

import mediaQuery from 'utils/mediaQuery';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    marginBottom: 40,
    padding: [0, 40],

  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [mediaQuery(BreakPoints.Small)]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  divider: {
    width: '100%',
    height: 2,
  },
  title: {
    fontSize: 30,
  },
  imageContainer: {
    maxWidth: '50%',
    [mediaQuery(BreakPoints.Small)]: {
      maxWidth: '100%',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '50%',
    padding: 30,
    gap: 20,
    [mediaQuery(BreakPoints.Small)]: {
      maxWidth: '100%',
    },
  },
  aboutText: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '1.6',

  },
}, {
  name: 'about',
});

export default useStyles;
